import http from "@/service/HttpService";
import axios from "axios";

export default class FactorService {
    fetchAll(priceCalculator) {
        return http.get(`additional-factor?filter[factor_type]=rider&filter[price_calculator_id]=${priceCalculator}&per_page=10000&sort=position`).then(d => d.data);
    }

    create(entity) {
        return http.post(`additional-factor`, entity).then(response => response.data);
    }

    update(entity) {
        return http.put(`additional-factor/${entity.id}`, entity).then(response => response.data);
    }

    delete(entity) {
        return http.delete(`additional-factor/${entity.id}`).then(response => response.data);
    }

    bulkReorder(entities) {
        const requests = [];
        for(const entity of entities) {
            requests.push(http.put(`additional-factor/${entity.id}`, {id: entity.id, position: entity.position}));
        }

        return axios.all(requests).then()
    }

    bulkDelete(entities) {
        const requests = [];
        for(const entity of entities) {
            requests.push(http.delete(`additional-factor/${entity.id}`));
        }

        return axios.all(requests).then()
    }
}
