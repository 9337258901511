<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Confirm" :modal="true">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="entity" v-html="title"></span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="$emit('hideDialog')"/>
      <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="doDelete" />
    </template>
  </Dialog>
</template>

<script>

import RiderService from "@/service/RiderService";

export default {
  name: "RiderDelete",
  emits: ['hideDialog','deleted'],
  props: {
    entity: Object,
    dialogVisible: Boolean
  },
  entityService: null,

  computed: {
    visible: {
      get() { return this.dialogVisible },
      set(visible) {this.$emit('hideDialog', visible)}
    },
    title() {
      return `Are you sure you want to delete <b>${this.entity.title}</b>?`;
    }
  },

  created() {
    this.entityService = new RiderService();
  },

  methods: {
    doDelete() {
      this.entityService.delete(this.entity).then(response => {
        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Rider deleted', life: 3000});
        this.$emit('deleted', response);
      });
    }
  }
}
</script>

<style scoped>

</style>
